define(['lodash', 'tpaComponents/common/TPABaseUrlBuilder'], function (_, TPABaseUrlBuilder) {
    'use strict';

    const maxRouterDataLength = 400;

    const TpaUrlBuilder = function (baseUrl) {
        TPABaseUrlBuilder.call(this, baseUrl);
    };

    TpaUrlBuilder.prototype = _.assign(new TPABaseUrlBuilder(), {
        addCacheKiller(cacheKiller) {
            return this.addQueryParam('cacheKiller', cacheKiller);
        },

        addInstance(instance) {
            return this.addQueryParam('instance', instance);
        },

        addConsentPolicy(header) {
            return this.addQueryParam('consent-policy', decodeURIComponent(header));
        },

        addWidth(width) {
            return this.addQueryParam('width', width);
        },

        addLocale(locale) {
            return this.addQueryParam('locale', locale);
        },

        addLang(lang) {
            return this.addQueryParam('lang', lang);
        },

        addDateNumberFormat(dateNumberFormat) {
            return this.addQueryParam('dateNumberFormat', dateNumberFormat);
        },

        addIsPrimaryLanguage(isPrimaryLanguage) {
            return this.addQueryParam('isPrimaryLanguage', isPrimaryLanguage);
        },

        addCurrency(currency) {
            return this.addQueryParam('currency', currency);
        },

        addCurrentCurrency(currentCurrency) {
            return this.addQueryParam('currentCurrency', currentCurrency);
        },

        addCommonConfig(commonConfig) {
            let commonConfigQueryParam = _.isString(commonConfig) ? commonConfig : '';
            if (_.isObject(commonConfig)) {
                commonConfigQueryParam = {};
                //Needed since the Aspect in the server expects BSI according to it's proto: https://github.com/wix-private/fed-infra/blob/master/fed-infra-protos/src/main/proto/common-config.proto#L26
                const mappedKeys = {bsi: 'BSI'};
                //Removing unneeded data that breaks JSON.parse
                const denyList = ['consentPolicyHeader', 'consentPolicy'];
                Object.keys(commonConfig).forEach(function manipulateKeys(key) {
                    if (mappedKeys[key]) {
                        commonConfigQueryParam[mappedKeys[key]] = commonConfig[key];
                    }
                    if (!_.includes(denyList, key, 0) && typeof commonConfig[key] !== 'function') {
                        commonConfigQueryParam[key] = commonConfig[key];
                    }
                });
                commonConfigQueryParam = JSON.stringify(commonConfigQueryParam);
            }
            return this.addQueryParam('commonConfig', commonConfigQueryParam);
        },

        addTimeZone(timeZone) {
            return this.addQueryParam('tz', timeZone);
        },

        addSiteRevision(revision) {
            return this.addQueryParam('siteRevision', String(revision));
        },

        addViewMode(viewMode) {
            return this.addQueryParam('viewMode', viewMode);
        },

        addCompId(compId) {
            return this.addQueryParam('compId', compId);
        },

        addViewerCompId(viewerCompId) {
            return this.addQueryParam('viewerCompId', viewerCompId);
        },

        addDeviceType(deviceType) {
            return this.addQueryParam('deviceType', deviceType);
        },

        addStandalone(isStandalone) {
            return this.addQueryParam('standalone', isStandalone);
        },

        addEndpointType(endpointType) {
            return this.addQueryParam('endpointType', endpointType);
        },

        addOrigCompId(origCompId) {
            return this.addQueryParam('origCompId', origCompId);
        },

        addExternalId(externalId) {
            return this.addQueryParam('externalId', externalId);
        },

        addOrigin(origin) {
            return this.addQueryParam('origin', origin);
        },

        addPageId(pageId) {
            return this.addQueryParam('pageId', pageId);
        },

        addHeight(height) {
            return this.addQueryParam('height', height);
        },

        addViewerSessionId(vsi) {
            return this.addQueryParam('vsi', vsi);
        },

        addRegionalLanguage(language) {
            return this.addQueryParam('regionalLanguage', language);
        },

        addCbi(bi) {
            return this.addQueryParam('cbi', bi);
        },

        addRouterData(routerData, isExperimentOpen) {
            try {
                let json;
                if (routerData) {
                    if (isExperimentOpen('sv_tpaDecodeRouterData')) {
                        json = window.btoa(JSON.stringify(routerData));
                    } else {
                        json = JSON.stringify(routerData);
                    }
                    if (json.length < maxRouterDataLength) {
                        return this.addQueryParam('routerData', json);
                    }
                }
                return this;
            } catch (e) {
                return this;
            }
        }
    });

    return TpaUrlBuilder;
});
